<template>
    <div>
      <center v-if="load">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </center>
      <div v-else>
        <div class="d-flex justify-content-end mt-0" v-if="moduleDetails.progress" >
        <div class="d-flex justify-content-center ">
            <p class="mt-3">Your progress</p>
            <vue-apex-charts
                type="radialBar"
                height="120"
                width="120"
                :options="goalOverviewRadialBarProgress.chartOptions"
                :series="goalOverviewRadialBarProgress.series"
            />
        </div>
      </div>
        <b-row>

            <b-col md="12">
                <b-card :class="$store.state.appConfig.layout.skin == 'dark' ? 'bg-custom-card-dark' : ''">
                    <b-row class="pt-0" v-if="moduleDetails.topics[0].description">
                        <b-col md="12" class="mb-3">
                            
                          <p class="mt-3">{{moduleDetails.topics[0].description}} </p>
                            
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="12" class="mb-2">
                            <div class="d-flex justify-content-between">
                              <h5 class="mt-1">Follow the map to complete your quest</h5> 
                              <b-button class="btn rounded-pill " variant="primary" @click="resetModule">
                                Reset
                              </b-button>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row v-if="moduleDetails">
                        <b-col sm="6" md="3" lg="2" class="mb-2" v-for="item in moduleDetails.topics" :key="item.id">
                            <div class="d-flex justify-content-center align-items-center h-100">
                                <div class="topic-container">
                                    <div :class="item.status == 'active' ? 'topic-image-container cursor-pointer active-topic' : item.status == 'inActive' ? 'topic-image-container inactive-topic': 'topic-image-container'" @click="next(item)">
                                        <div class="topic-image">
                                            <img v-if="item.cover_image_path" :src="item.cover_image_path" alt="" height="100%">
                                            <img v-else src="https://i0.wp.com/picjumbo.com/wp-content/uploads/beautiful-spring-summer-day-in-green-nature-mountains-free-photo.jpg?w=2210&quality=70" alt="" height="100%">
                                            <span class="completed-topic d-flex justify-content-center align-items-center text-light" v-if="item.status == 'complete'">
                                            </span>
                                            <span class="check-topic d-flex justify-content-center align-items-center" v-if="item.status == 'complete'">
                                                <feather-icon
                                                    icon="CheckIcon"
                                                    size="40"
                                                    class="font-weight-bolder text-light"
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div class="text-center pt-1">
                                        <h5 >
                                            {{item.name}}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            
        </b-row>
      </div>
      
    </div>
</template>
<script>
import {
  BCard, 
  BRow, 
  BCol, 
  BCardText, 
  BButton,
  BLink, 
  BListGroup, 
  BListGroupItem, 
  BCardTitle, 
  BCardBody,
  BTab, BTabs,
  BAlert,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from "vuex";
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    VueApexCharts,
    BCard, 
    BRow, 
    BCol, 
    BCardText, 
    BButton,
    BLink, 
    BListGroup, 
    BListGroupItem, 
    BCardTitle, 
    BCardBody,
    BTab, BTabs,
    BAlert,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  computed:{
    ...mapGetters({
        moduleDetails: "modules/item",
        load: "modules/load",
    }),
    id(){
      return this.$route.params.id ? this.$route.params.id : null
    },
    goalOverviewRadialBarProgress(){
        return {
        series: [this.moduleDetails.progress],
        chartOptions: {
          chart: {
            // height: 90,
            type: 'radialBar',
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
                hollow: {
                margin: 0,
                size: '65%',
                background: this.$store.state.appConfig.layout.skin == 'dark' ? '#000' : '#fff',
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: 'front',
                dropShadow: {
                  enabled: true,
                  top: 0,
                  left: 0,
                  blur: 5,
                  opacity: 0.24
                }
              },
              track: {
                background: this.$store.state.appConfig.layout.skin == 'dark' ? '#000' : '#fff',
                strokeWidth: '60%',
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  bottom: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.55
                }
              },
          
              dataLabels: {
                show: true,
                name: {
                  offsetY: 0,
                  show: false,
                  color: '#888',
                  fontSize: '22px'
                },
                value: {
                  formatter: function(val) {
                    return parseInt(val)+'%';
                  },
                  color: this.$store.state.appConfig.layout.skin == 'dark' ? '#fff' : '#111',
                  fontSize: '15px',
                  offsetY: 5,
                  show: true,
                }
              }
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 1,
              gradientToColors: ['#E84185'],
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            }
          },
          stroke: {
            lineCap: 'round'
          },
          labels: ['Percent'],
        },
      }
    }
    
  },
  data(){
    return {
        
    }
  },
  methods:{
    init(){
      if(this.id){
        this.$store.dispatch('modules/get', this.id).then(_=>{
        })
    }
    },
    resetModule(){
      this.$store.dispatch('modules/reset', this.id).then(_=>{
            this.init();
        })
    },
    next(item){
        if(item.status == 'active'){
            this.$router.push({name:'lesson',params:{id:item.id}})
            // this.$router.push({name:'lesson',params:{id:item.id,process:this.moduleDetails.progress}})
        }
    }
  },
  mounted(){
    this.init();
  }
}
</script>
<style lang="">
    
</style>